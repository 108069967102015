<template>
  <div id="main-container">
    <div class="grid lg:grid-cols-12 gap-6">
      <div
        class="lg:col-span-8 xl:col-span-9 shadow-sm border rounded-md overflow-hidden"
      >
        <div
          class="flex justify-between flex-wrap items-center px-3 py-3 border-b"
        >
          <div class="flex md:flex-wrap flex-wrap-reverse mt-3 md:mt-0">
            <div
              class="w-full md:w-auto mt-3 md:mt-0"
              v-if="stateForCreditNote"
            >
              <div class="relative md:min-w-[260px] lg:min-w-[320px]">
                <div class="flex items-center relative text-gray-600">
                  <div
                    class="absolute top-0 left-3 w-6 h-full flex items-center"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-barcode"
                      class="w-4 h-4"
                    />
                  </div>
                  <input
                    type="search"
                    v-model="searchCreditNotes"
                    class="border pl-9 py-2 text-sm outline-none w-full rounded-md bg-gray-50"
                    placeholder="Buscar orden de compra"
                    @keydown.enter="searchCreditNote"
                    @keydown.enter.prevent
                  />
                </div>
                <div
                  v-if="showResultsCreditNote"
                  class="absolute top-[2.3rem] left-0 right-0 z-10 py-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-32 overflow-y-auto"
                >
                  <div v-if="loadingSearchCreditNote">
                    <div class="px-3">
                      <div
                        class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                      ></div>
                      <div
                        class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                      ></div>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      v-if="!resultLengthCreditNote"
                      class="px-3 py-1 bg-gray-50 text-gray-400"
                      @click="showResultsCreditNote = false"
                    >
                      <strong>{{ searchCreditNotes }}</strong> no existe
                    </div>
                    <div
                      v-else
                      v-for="(result, i) in resultSearchCreditNote"
                      :key="i"
                      class="odd:bg-white even:bg-slate-50"
                      @blur="showResultsCreditNote = false"
                    >
                      <div
                        class="py-2 px-3 pb-1 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-blue-500 uppercase"
                        @click="applyCreditNote(result.id, result.total)"
                      >
                        {{ result.proveedor.razon_social }}
                        <div>
                          <span
                            class="px-3 py-1 rounded-md text-orange-500 bg-orange-50 text-xs font-semibold"
                          >
                            {{ money() }} {{ format(result.total) }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="bg-blue-50 text-blue-500 py-1 px-3 rounded-md md:ml-3 w-full md:w-auto font-semibold text-sm hover:bg-blue-500 hover:text-white h-[38px]"
              @click="activeCreditNoteSearch"
            >
              Cargar nota de credito
            </button>
          </div>
          <div class="flex md:flex-wrap flex-wrap-reverse mt-3 md:mt-0">
            <div
              class="w-full md:w-auto mt-3 md:mt-0"
              v-if="statusToLookUpThePurchaseOrder"
            >
              <div class="relative md:min-w-[260px] lg:min-w-[320px]">
                <div class="flex items-center relative text-gray-600">
                  <div
                    class="absolute top-0 left-3 w-6 h-full flex items-center"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-barcode"
                      class="w-4 h-4"
                    />
                  </div>
                  <input
                    type="search"
                    v-model="searchPurchaseOrders"
                    class="border pl-9 py-2 text-sm outline-none w-full rounded-md bg-gray-50"
                    placeholder="Buscar orden de compra"
                    @keydown.enter="searchPurchaseOrder"
                    @keydown.enter.prevent
                  />
                </div>
                <div
                  v-if="showResultsPurchaseOrder"
                  class="absolute top-[2.3rem] left-0 right-0 z-10 py-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-32 overflow-y-auto"
                >
                  <div v-if="loadingSearchPurchaseOrder">
                    <div class="px-3">
                      <div
                        class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                      ></div>
                      <div
                        class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                      ></div>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      v-if="!resultLengthPurchaseOrder"
                      class="px-3 py-1 bg-gray-50 text-gray-400"
                      @click="showResultsPurchaseOrder = false"
                    >
                      <strong>{{ searchPurchaseOrders }}</strong> no existe
                    </div>
                    <div
                      v-else
                      v-for="(result, i) in resultSearchPurchaseOrder"
                      :key="i"
                      class="odd:bg-white even:bg-slate-50"
                      @blur="showResultsPurchaseOrder = false"
                    >
                      <div
                        class="py-2 px-3 pb-1 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-blue-500 uppercase"
                        @click="addDataFromPurchaseOrder(result.detalle)"
                      >
                        {{ result.proveedor.razon_social }}
                        <div>
                          <span
                            class="px-3 py-1 rounded-md text-orange-500 bg-orange-50 text-xs font-semibold"
                          >
                            {{ money() }} {{ format(result.total) }}
                          </span>
                          <span
                            class="px-3 py-1 rounded-md text-green-500 bg-green-50 text-xs font-semibold ml-3"
                          >
                            {{ result.fecha_de_emision }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="bg-green-500 text-white py-1 px-3 rounded-md md:ml-3 w-full md:w-auto font-semibold text-sm hover:bg-green-600 h-[38px]"
              @click="activePurchaseOrderSearch"
            >
              Cargar orden de compra
            </button>
          </div>
        </div>
        <div class="px-3 py-3">
          <SearchProduct
            @send-data="addProducts"
            :isShopping="true"
            :typeVoucher="'NINGUNA'"
          />
          <!-- detalle de la orden de compra -->
          <div class="overflow-x-auto mt-6 max-h-[calc(100vh-210px)]">
            <table class="table-auto w-full">
              <TableHead :headers="header" class="sticky top-0" />
              <LoadingTables v-if="!loading" :columns="8" />
              <tbody
                class="text-gray-500 text-left border-t-[1px]"
                v-else
                id="dataTable"
              >
                <tr
                  class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                  v-for="(order, i) in arrayOfDetails"
                  :key="i"
                >
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                  >
                    {{ order.product }}
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                  >
                    <input
                      type="number"
                      min="1"
                      :value="order.quantity"
                      class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[45px] rounded-md border-2 border-blue-100 text-center"
                      @blur="handleQuantityChange(i, $event, order.ivaAplied)"
                      @keyup.enter="
                        handleQuantityChange(i, $event, order.ivaAplied)
                      "
                    />
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                  >
                    <input
                      type="number"
                      min="1"
                      :value="order.unitPrice"
                      class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[80px] rounded-md border-2 border-blue-100 text-center"
                      @blur="handlePriceChange(i, $event, order.ivaAplied)"
                      @keyup.enter="
                        handlePriceChange(i, $event, order.ivaAplied)
                      "
                    />
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                  >
                    {{ money() }} {{ format(order.priceSale) }}
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase font-bold"
                  >
                    {{ money() }} {{ format(order.total) }}
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                  >
                    <input
                      type="date"
                      :value="order.expired"
                      class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[120px] rounded-md border-2 border-blue-100 text-center"
                      @input="handleExpiredChange(i, $event)"
                    />
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                  >
                    <input
                      type="text"
                      :value="order.descriptionLot"
                      class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[150px] rounded-md border-2 border-blue-100 text-left"
                      @blur="handleDescriptionLotChange(i, $event)"
                      @keyup.enter="handleDescriptionLotChange(i, $event)"
                      placeholder="Desc. Lote"
                      maxlength="35"
                    />
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 text-right whitespace-nowrap"
                  >
                    <button
                      class="bg-red-100 text-red-500 hover:text-white py-1 px-2 rounded-md text-sm active:scale-105 hover:bg-red-500"
                      @click="deleteDetailsTableToTable(i)"
                    >
                      <font-awesome-icon
                        icon="fa-solid fa-trash"
                        class="w-3 h-3"
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <ShoppingTotalDetails
            :iva="ivaOfTheOrder"
            :subTotal="subTotalOfTheOrder"
            :total="totalOfTheOrder"
            :totalCreditNote="totalCreditNote"
            :totalCreditNoteDiference="totalCreditNoteDiference"
          />

          <!-- Componente para las compras a credito -->
          <div
            class="px-3 py-3 rounded-md border-2 border-blue-100 bg-blue-50 mt-3"
            v-show="stateForAccountsPayableSetup"
          >
            <h2 class="text-blue-500 font-semibold text-md pb-3">
              Configuracion de la compra a crédito
            </h2>
            <div class="flex flex-wrap justify-between gap-4">
              <!-- Importe de adelanto -->
              <label class="block w-full md:w-[48%]">
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Importe inicial
                </span>
                <input
                  type="number"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
                  v-model="dataForm.initialAmount"
                  placeholder="Importe incial de la compra a credito..."
                  @keydown.enter.prevent
                  min="0"
                />
              </label>
              <!-- Importe de adelanto -->
              <label class="block w-full md:w-[48%]">
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Fecha de pago
                </span>
                <input
                  type="date"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
                  v-model="dataForm.paymentDate"
                  @keydown.enter.prevent
                />
              </label>
            </div>
            <div
              class="border-2 border-orange-100 bg-orange-50 py-3 px-3 rounded-md mt-3 text-orange-700"
            >
              Al realizar la compra a crédito usted estará generando una cuenta
              por pagar a dicho proveedor, esa cuenta por pagar puedes ubicarlo
              en el módulo de pagos, item
              <code class="bg-orange-100">Cuentas por pagar</code>
            </div>
          </div>
        </div>
      </div>
      <div
        class="lg:col-span-4 xl:col-span-3 rounded-md overflow-hidden border border-gray-100 shadow-sm"
      >
        <div
          class="px-3 py-5 bg-blue-500 text-white text-2xl font-semibold text-center"
        >
          {{ money() }} {{ format(totalOfTheOrder) }}
        </div>

        <div class="px-3 py-3 space-y-4">
          <div class="relative" id="contentSearchSupplier">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Proveedores
            </span>
            <input
              type="text"
              v-model="dataForm.searchSupplier"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 border-gray-200 focus:border-blue-500"
              placeholder="Buscar por razón social o N° de documento..."
              @input="searchSupplier"
              @keydown.enter.prevent
            />
            <div
              v-if="showResultsSupplier"
              class="absolute top-[4.2rem] left-0 right-0 z-10 py-2 bg-white border border-gray-300 rounded-md shadow-lg"
            >
              <div v-if="loadingSearchSupplier">
                <div class="px-3">
                  <div
                    class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                  ></div>
                  <div
                    class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                  ></div>
                </div>
              </div>
              <div v-else>
                <div
                  v-if="!resultLengthSupplier"
                  class="px-3 py-1 bg-gray-50 text-gray-400"
                  @click="showResultsSupplier = false"
                >
                  <strong>{{ dataForm.searchSupplier }}</strong> no existe
                </div>
                <div
                  v-else
                  v-for="(result, i) in resultSearchSupplier"
                  :key="i"
                >
                  <div
                    class="py-1 px-3 mt-1 mb-1 pb-1 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-blue-500"
                    @click="
                      changeSupplierID(
                        result.id,
                        result.nombre_comercial,
                        result.fecha_limite_de_pagos
                      )
                    "
                    @blur="showResultsSupplier = false"
                  >
                    {{ result.nombre_comercial }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- lista de comprobante -->
          <label class="block w-full">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Comprobante de compra
            </span>
            <VouchersOfSale @send-data="getSelectVouchersOfSale" />
          </label>
          <!-- numero de comprobante de compra -->
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              N° de comprobante
            </span>
            <input
              type="text"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
              v-model="dataForm.numberVoucherOfShopping"
              placeholder="N° de comprobante de la compra..."
              @keydown.enter.prevent
              maxlength="13"
            />
          </label>
          <!-- lista de tipo de ventas -->
          <label class="block w-full">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Tipo de compra
            </span>
            <TypeOfSale
              :selectOption="dataForm.idTypeOfSale"
              @send-data="getSelectTypeOfSale"
            />
          </label>
          <!-- lista de tipos de pagos -->
          <label class="block w-full">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Tipo de pago
            </span>
            <TypeOfPayment
              :selectOption="dataForm.idTypeOfPayment"
              @send-data="getSelectTypeOfPayment"
            />
          </label>
          <!-- numero de la entidad bancaria -->
          <label class="block" v-show="stateInputTypePayment">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Entidad bancaria
            </span>
            <input
              type="text"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
              v-model="dataForm.entityBank"
              placeholder="Entidad bancaria..."
              @keydown.enter.prevent
              maxlength="60"
            />
          </label>
          <!-- numero de operacion de la transferencia u otra -->
          <label class="block" v-show="stateInputTypePayment">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              N° de operación
            </span>
            <input
              type="text"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
              v-model="dataForm.numberOperation"
              placeholder="N° de operación..."
              @keydown.enter.prevent
              maxlength="30"
            />
          </label>
          <!-- Descuento aplicado de la compra -->
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Descuento aplicado
            </span>
            <input
              type="number"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 border-orange-500 focus:border-blue-500 resize-none"
              v-model="dataForm.discountApplied"
              placeholder="N° de operación..."
              @keydown.enter.prevent
              maxlength="30"
              @keydown.enter="discountApliedToOrder"
              @blur="discountApliedToOrder"
            />
          </label>
          <button
            class="bg-blue-500 text-white px-4 py-3 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center w-full justify-center"
            @click="insert"
            :disabled="spinner === true"
          >
            <div class="flex items-center" v-if="spinner">
              <svg class="w-4 h-4 mr-3 animate-spin">
                <use href="../../assets/svg/icon.svg#spinnerForBlue" />
              </svg>
              Registrando compra
            </div>
            <span v-else>Registrar compra</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "../../api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import ShoppingTotalDetails from "./ShoppingTotalDetails.vue";
import { money, format } from "@/publicjs/money";
import axios from "@/api";
import { observeElement } from "@/observer";
import { onMounted, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";

import { generateUtility } from "@/publicjs/generateUtility";

// Public components
import TypeOfPayment from "@/components/PublicComponents/TypeOfPayment.vue";
import TypeOfSale from "@/components/PublicComponents/TypeOfSale.vue";
import VouchersOfSale from "@/components/PublicComponents/VouchersOfSale.vue";
import { voucherSales } from "@/publicjs/vouchers";
import { percentage } from "@/publicjs/convertToPercentage";
import { IVA_LOCAL_STORE } from "@/publicjs/localStorage";
import calculatePaymentDate from "@/publicjs/calculatePaymentDate";

import SearchProduct from "@/components/Sales/Searchs/SearchProduct.vue";

export default {
  name: "MainShopping",
  components: {
    LoadingTables,
    TableHead,
    ShoppingTotalDetails,
    TypeOfSale,
    TypeOfPayment,
    VouchersOfSale,
    SearchProduct,
  },
  setup() {
    const loading = ref(true);

    const spinner = ref(false); // Sirve para mostrar un loading al momento de enviar información

    //Iva para calcular el subtotal y el igv
    const [TO_DIVIDE, TO_MULTIPLY] = IVA_LOCAL_STORE();

    //Variables para calcular el total de orden de compra
    const ivaOfTheOrder = ref(0);
    const subTotalOfTheOrder = ref(0);
    const totalOfTheOrder = ref(0);
    const totalCreditNote = ref(0);
    const totalCreditNoteDiference = ref(0);

    const header = [
      [
        {
          title: "Descripción",
        },
        {
          title: "Cantidad",
        },
        {
          title: "P. COMPRA",
        },
        {
          title: "P. venta",
        },
        {
          title: "Total",
        },
        {
          title: "vencimiento",
        },
        {
          title: "Desc. Lote",
        },
        {
          title: "acciones",
        },
      ],
    ];

    const dataForm = ref({
      idStore: 0,
      idUser: 0,
      idSupplier: 0,
      idTypeOfPayment: null,
      idTypeOfSale: null,
      searchSupplier: "",
      numberVoucherOfShopping: null,
      entityBank: null,
      numberOperation: null,
      discountApplied: 0,
      idVoucher: 0,
      numberVoucher: "",
      serieVoucher: "",
      initialAmount: 0,
      paymentDate: null,
      idPurchaseOrder: 0,
      idCreditNote: 0,
    });

    //variable para almacenar detalles de la orden de compra
    const arrayOfDetails = ref([]);

    //varibles para los inputs de busqueda
    const showResultsSupplier = ref(false);
    const loadingSearchSupplier = ref(true);
    const resultSearchSupplier = ref([]);
    const resultLengthSupplier = ref(false);
    // esta funcion sirve para buscar usuario por nombre
    const searchSupplier = async () => {
      if (dataForm.value.searchSupplier.length <= 0) {
        // errorActions(
        //   "Por favor ingresa <strong>la razón sozial o el N° de documento del proveedor</strong>"
        // );
        return;
      }

      loadingSearchSupplier.value = true;
      showResultsSupplier.value = true;
      resultLengthSupplier.value = false;
      const response = await axios
        .get(
          `proveedores/?page=1&per_page=20&search=${dataForm.value.searchSupplier}`,
          {}
        )
        .catch((error) => errorActions(error));

      const dataSupplier = await response.data.data;

      resultSearchSupplier.value = dataSupplier.filter(
        (supplier) => supplier.estado === 1
      );

      if (resultSearchSupplier.value.length > 0) {
        resultLengthSupplier.value = true;
      }
      loadingSearchSupplier.value = false;
    };
    //esta funcion sirve para seleccionar al usuario por su id y se almacena en dataForm.idUser
    const changeSupplierID = async (id, fullName, day = 0) => {
      dataForm.value.idSupplier = await id;
      dataForm.value.searchSupplier = await fullName;
      //calcular la fecha de pago
      if (parseInt(day) > 0) {
        dataForm.value.paymentDate = calculatePaymentDate(day);
      } else {
        dataForm.value.paymentDate = null;
      }
      showResultsSupplier.value = false;
    };

    //Variables para la busqueda de producto
    const addProducts = async (data) => {
      const {
        idProduct,
        taxFree,
        unitMeasure,
        quantity,
        shoppingPrice,
        productName,
        igvApplied,
        utility,
        stock,
        salePrice,
      } = data;

      const beforeUnitPrice = stock;
      //parseamos el iva del producto
      const configureIva = parseFloat(igvApplied) / 100;

      const subTotal =
        Math.round((shoppingPrice / (configureIva + 1)) * 100) / 100;
      const iva = Math.round(subTotal * configureIva * 100) / 100;
      const total = quantity * shoppingPrice;

      // Buscar el producto en la lista existente
      const existingProductIndex = arrayOfDetails.value.findIndex(
        (order) => order.idProduct === idProduct
      );

      if (existingProductIndex !== -1) {
        // El producto ya existe en la lista, sumar la cantidad y actualizar los precios
        const existingProduct = arrayOfDetails.value[existingProductIndex];
        const newQuantity = existingProduct.quantity + quantity;

        existingProduct.quantity = newQuantity;
        existingProduct.subTotal =
          Math.round(subTotal * newQuantity * 100) / 100;
        existingProduct.iva =
          Math.round(existingProduct.subTotal * configureIva * 100) / 100;
        existingProduct.total = existingProduct.quantity * shoppingPrice;
      } else {
        const orderData = {
          idProduct,
          product: productName,
          taxFree,
          unitMeasure,
          quantity,
          unitPrice: parseFloat(shoppingPrice),
          subTotal,
          iva,
          total,
          ivaAplied: igvApplied,
          expired: null,
          priceSale: salePrice,
          utility,
          descriptionLot: null,
          currentStock: parseFloat(stock),
          beforeUnitPrice: parseFloat(beforeUnitPrice),
        };
        //añadimos el nuevo objeto a la oder de compra
        arrayOfDetails.value.push(orderData);
      }

      calculateTheTotal(arrayOfDetails.value);
    };

    //funcion para eliminar un registro del arreglo de la orden de compra

    const deleteDetailsTableToTable = (index) => {
      arrayOfDetails.value.splice(index, 1);

      //llamamos la funcion para calcular la orden de compra y le pasamos como parametro el detalle de la orden
      calculateTheTotal(arrayOfDetails.value);
    };

    //funcion para calcular el total de la orden de compra, incluyendo el iva, subtotal y total
    const calculateTheTotal = (order) => {
      //nos aseguramos que la variables se inicialicen en cero
      totalOfTheOrder.value = 0;
      subTotalOfTheOrder.value = 0;
      ivaOfTheOrder.value = 0;

      //recorremos todo el detalle de la orden de compra
      order.forEach((element) => {
        totalOfTheOrder.value += element.total;
        subTotalOfTheOrder.value += element.subTotal;
        ivaOfTheOrder.value += element.iva;
      });
    };

    //funcion para cambiar la cantidad de la orden de compra
    const handleQuantityChange = (index, event, ivaAplied) => {
      const newQuantity = (!isNaN(event.target.value) && event.target.value !== '')
        ? parseFloat(event.target.value)
        : 1.0;
      // console.log(event.target.value);
      arrayOfDetails.value[index].quantity = parseFloat(newQuantity);
      updateDetailData(index, ivaAplied);
    };
    //funcion para cambiar la cantidad de la orden
    const handleExpiredChange = (index, event) => {
      const newExpired =
        event.target.value !== null ? event.target.value : null;
      arrayOfDetails.value[index].expired = newExpired;
    };

    //funcion para cambiar el precio en el detalle
    const handlePriceChange = (index, event, ivaAplied) => {
      const newPrice = (!isNaN(event.target.value) && event.target.value !== '')
        ? parseFloat(event.target.value)
        : 1.0;

      arrayOfDetails.value[index].unitPrice = newPrice;

      //CALCULANDO EL PROMEDIO PONDERADO

      const utilities = parseFloat(arrayOfDetails.value[index].utility) / 100;
      const stock = parseFloat(arrayOfDetails.value[index].currentStock);
      const quantity = parseFloat(arrayOfDetails.value[index].quantity);

      const price =
        quantity * parseFloat(arrayOfDetails.value[index].unitPrice);

      const newStock = quantity + stock;

      const amountTotal =
        price + parseFloat(arrayOfDetails.value[index].beforeUnitPrice);

      const precingProductCurrent = amountTotal / newStock;

      const totalSales = precingProductCurrent * utilities;

      const priceSales =
        parseFloat(arrayOfDetails.value[index].unitPrice) + totalSales;

      //CALCULANDO EL PROMEDIO PONDERADO

      arrayOfDetails.value[index].priceSale =
        utilities > 0
          ? parseFloat(priceSales.toFixed(2))
          : parseFloat(arrayOfDetails.value[index].unitPrice);
      updateDetailData(index, ivaAplied);
    };
    //funcion para cambiar la descripcion del lote
    const handleDescriptionLotChange = (index, event) => {
      arrayOfDetails.value[index].descriptionLot = event.target.value;
    };

    //funciona para actualizar la orden
    const updateDetailData = (index, ivaAplied) => {
      const order = arrayOfDetails.value[index];
      const totalOrder = order.quantity * parseFloat(order.unitPrice);

      //parseamos el iva del producto
      const configureIva = parseFloat(ivaAplied) / 100;

      //volvemos a calcular el total de la orden de compra del producto seleccionado
      order.subTotal =
        Math.round((totalOrder / (configureIva + 1)) * 100) / 100;
      order.iva = Math.round(order.subTotal * configureIva * 100) / 100;
      order.total = totalOrder;

      //llamamos la funcion para calcular la orden de compra y le pasamos como parametro el detalle de la orden
      calculateTheTotal(arrayOfDetails.value);
    };

    onMounted(async () => {
      //obtenemos el id de la sucursal donde el usuario inicio sesión
      const localStore = await JSON.parse(localStorage.getItem("access"));
      dataForm.value.idStore = localStore.user.store;
      dataForm.value.idUser = localStore.user.key;

      observeElement("#main-container");
      observeElement("#searchproduct");
      observeElement("#dataTable");
      observeElement("#contentSearchSupplier");
    });

    //funcion para insertar una orden de compra
    const insert = async () => {
      let hasInvalidNumber = false;
      arrayOfDetails.value.forEach((item) => {
        if (
          isNaN(item.total) ||
          isNaN(item.priceSale) ||
          isNaN(item.unitPrice) ||
          isNaN(item.currentStock) ||
          isNaN(item.beforeUnitPrice)
        ) {
          hasInvalidNumber = true;
        }
      });
      if (hasInvalidNumber) {
        errorActions(
          "Error en listado de productos, hay totales que no son numeros "
        );
        return;
      }

      if (
        isNaN(totalOfTheOrder.value) ||
        isNaN(subTotalOfTheOrder.value) ||
        isNaN(ivaOfTheOrder.value)
      ) {
        errorActions("Error en los totales de la compra");
        return;
      }

      //variable donde almacenamos los datos del comprobantes
      spinner.value = true;
      const dataVoucher = await voucherSales(dataForm.value.idVoucher);
      dataForm.value.idVoucher = dataVoucher.id;
      dataForm.value.numberVoucher = dataVoucher.numberCurrent;
      dataForm.value.serieVoucher = dataVoucher.serie;
      // const printFormat = dataVoucher.printFormat;

      if (arrayOfDetails.value.length <= 0) {
        errorActions(
          "Por favor agrega productos a lista de detalles de compra"
        );
        spinner.value = false;
        return;
      }
      if (dataForm.value.idVoucher <= 0) {
        errorActions(
          "Selecciona un  <strong>TIPO DE COMPROBANTE</strong> para poder realizar la compra..."
        );

        spinner.value = false;
        return;
      }
      if (dataForm.value.idSupplier <= 0) {
        errorActions(
          "Por favor selecciona un proveedor para realizar <strong>LA COMPRA</strong>"
        );

        spinner.value = false;
        return;
      }
      if (
        dataForm.value.numberVoucherOfShopping === null ||
        dataForm.value.numberVoucherOfShopping.length <= 7
      ) {
        errorActions(
          "Por favor ingresa el numero del comprobante de <strong>LA COMPRA</strong>"
        );

        spinner.value = false;
        return;
      }
      if (dataForm.value.idTypeOfPayment === null) {
        errorActions(
          "Por favor selecciona un tipo de pago de la <strong>LA COMPRA</strong> realizada"
        );

        spinner.value = false;
        return;
      }
      if (dataForm.value.idTypeOfSale === null) {
        errorActions(
          "Por favor selecciona <strong>el tipo de compra realizada </strong>"
        );

        spinner.value = false;
        return;
      }
      //Validaciones para la cuentas por pagar
      if (
        dataForm.value.paymentDate === null &&
        dataForm.value.idTypeOfSale !== 1
      ) {
        errorActions(
          "Selecciona un fecha de pago para la  <strong>CUENTA POR PAGAR </strong>"
        );

        spinner.value = false;
        return;
      }

      try {
        const response = await axios.post(
          "compras",
          {
            idStore: dataForm.value.idStore,
            idUser: dataForm.value.idUser,
            idTypeOfSale: dataForm.value.idTypeOfSale,
            idTypeOfPayment: dataForm.value.idTypeOfPayment,
            idSupplier: dataForm.value.idSupplier,
            idVoucher: dataForm.value.idVoucher,
            numberVoucher: dataForm.value.numberVoucherOfShopping,
            entityBank: dataForm.value.entityBank,
            numberOperation: dataForm.value.numberOperation,
            totalOfTheOrder: totalOfTheOrder.value,
            subTotalOfTheOrder: subTotalOfTheOrder.value,
            ivaOfTheOrder: ivaOfTheOrder.value,
            discountApplied: dataForm.value.discountApplied,
            totalCreditNote: totalCreditNote.value,
            totalCreditNoteDiference: totalCreditNoteDiference.value,
            perception: 0,
            factor: 0,
            state: "VIGENTE",
            initialAmount: dataForm.value.initialAmount,
            paymentDate: dataForm.value.paymentDate,
            idPurchaseOrder: dataForm.value.idPurchaseOrder,
            idCreditNote: dataForm.value.idCreditNote,
            arrayDetail: arrayOfDetails.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.data;
        if (data.msg === true) {
          confirmationOfRecord(
            `La compra del proveedor <strong>${dataForm.value.searchSupplier}</strong>`
          );

          //reseteamos el total de la orden
          subTotalOfTheOrder.value = 0;
          ivaOfTheOrder.value = 0;
          totalOfTheOrder.value = 0;
          //limpiamos loca campos de la orden
          dataForm.value.discountApplied = 0;
          dataForm.value.paymentDate = null;
          dataForm.value.initialAmount = 0;
          dataForm.value.entityBank = "";
          dataForm.value.numberOperation = "";
          dataForm.value.numberVoucherOfShopping = "";
          dataForm.value.searchSupplier = "";
          dataForm.value.idSupplier = 0;
          arrayOfDetails.value = [];
        } else if (data.msg === "Request failed with status code 500") {
          errorActions("Request failed with status code 500");
        } else {
          errorActions(
            `Lo sentimos, no pudimos registrar la compra del proveedor <strong>${dataForm.value.searchSupplier}</strong>`
          );
        }
        spinner.value = false;
      } catch (error) {
        spinner.value = false;
        errorActions(error);
      }
    };

    //Estado para mostrar el input de busqueda de ordenes de compra
    const statusToLookUpThePurchaseOrder = ref(false);

    // Estado para mostrar o no los inputs de entidad bancaria y numero de operacion
    const stateInputTypePayment = ref(false);
    //Estado para mostrar la configuración de la cuenta por pagar
    const stateForAccountsPayableSetup = ref(false);

    const activePurchaseOrderSearch = () => {
      statusToLookUpThePurchaseOrder.value =
        !statusToLookUpThePurchaseOrder.value;
      stateForCreditNote.value = false;
    };

    //recepcion de datos desde el componente publico
    const getSelectTypeOfPayment = (data) => {
      dataForm.value.idTypeOfPayment = data;
      //Activamos los inputs para ingresar los datos como entidad bancaria y numero de operación
      if (data !== 1) {
        stateInputTypePayment.value = true;
      } else {
        stateInputTypePayment.value = false;
      }
    };
    const getSelectTypeOfSale = (data) => {
      dataForm.value.idTypeOfSale = data;
      //Activamos el componente para configurar las cuentas por cobrar
      if (data !== 1) {
        stateForAccountsPayableSetup.value = true;
      } else {
        stateForAccountsPayableSetup.value = false;
      }
    };
    const getSelectVouchersOfSale = (data) => {
      dataForm.value.idVoucher = data.id;
    };

    //funcion para aplicar el descuento a la compra

    const discountApliedToOrder = async () => {
      calculateTheTotal(arrayOfDetails.value);
      let amountDiscount;
      //Validamos si el input de descuento tiene valor,
      //si tiene valor le pasamos su valor  de lo contrario pasamos como parametro 0
      if (
        dataForm.value.discountApplied === "" ||
        dataForm.value.discountApplied === null
      ) {
        amountDiscount = totalOfTheOrder.value * percentage(0);
      } else {
        amountDiscount =
          totalOfTheOrder.value * percentage(dataForm.value.discountApplied);
      }
      //Descontamos el total de la compra
      const amountTotal = totalOfTheOrder.value - amountDiscount;
      const amountSubTotal = amountTotal / TO_DIVIDE;
      const amountIva = amountSubTotal * TO_MULTIPLY;

      //Para evitar inconsistencia de datos es necesario usar solo toFixed al momento de calcular el IVA Y EL SUBTOTAL
      ivaOfTheOrder.value = parseFloat(amountIva.toFixed(2));
      subTotalOfTheOrder.value = parseFloat(amountSubTotal.toFixed(2));
      totalOfTheOrder.value = parseFloat(amountTotal.toFixed(2));
    };

    //Funcion para cargar una orden de compra a una compra
    //Variables para la busqueda de producto
    const showResultsPurchaseOrder = ref(false);
    const loadingSearchPurchaseOrder = ref(true);
    const resultSearchPurchaseOrder = ref([]);
    const resultLengthPurchaseOrder = ref(false);
    const searchPurchaseOrders = ref("");
    // esta funcion sirve para buscar usuario por nombre
    const searchPurchaseOrder = async () => {
      if (searchPurchaseOrders.value.length <= 0) {
        errorActions(
          "Por favor ingresa <strong>el nombre de producto o su codigo de barra</strong>"
        );
        return;
      }

      try {
        loadingSearchPurchaseOrder.value = true;
        showResultsPurchaseOrder.value = true;
        resultLengthPurchaseOrder.value = false;
        const response = await axios
          .get(
            `orden-de-compras/?page=1&per_page=50&search=${searchPurchaseOrders.value}&idstore=${dataForm.value.idStore}&state=EMITIDO`
          )
          .catch((error) => errorActions(error));
        resultSearchPurchaseOrder.value = await response.data.data;
        if (resultSearchPurchaseOrder.value.length > 0) {
          resultLengthPurchaseOrder.value = true;
        }
        loadingSearchPurchaseOrder.value = false;
      } catch (error) {
        errorActions(
          "Upsss. No pudimos encontrar el producto debido a que hemos tenido unos inconvenientes"
        );
      }
    };

    //Const Funcion para agregar datos desde la orde de compra
    const addDataFromPurchaseOrder = async (arraDetailPurchaseOrder) => {
      arrayOfDetails.value = [];
      dataForm.value.idPurchaseOrder =
        arraDetailPurchaseOrder[0].idorden_de_compra;
      //Si el producto no existe agregamos un nuevo producto a la lista de arreglos de la orden de compra

      arraDetailPurchaseOrder.forEach((element) => {
        const orderData = {
          idProduct: element.idproducto,
          product: element.nombre_producto,
          taxFree: element.libre_de_impuesto,
          unitMeasure: element.unidad_de_medida,
          quantity: parseFloat(element.cantidad),
          unitPrice: parseFloat(element.precio_unitario),
          subTotal: parseFloat(element.subtotal),
          iva: parseFloat(element.igv),
          total: parseFloat(element.total),
          ivaAplied: parseFloat(element.igv_aplicado),
          expired: null,
          priceSale: generateUtility(element.precio_unitario, element.utilidad),
          utility: parseFloat(element.utilidad),
          descriptionLot: null,
        };

        //añadimos el nuevo objeto a la oder de compra
        arrayOfDetails.value.push(orderData);
      });
      showResultsPurchaseOrder.value = false;
      calculateTheTotal(arrayOfDetails.value);
    };

    //Estado para mostrar la configuración de la cuenta por pagar
    const stateForCreditNote = ref(false);

    const activeCreditNoteSearch = () => {
      stateForCreditNote.value = !stateForCreditNote.value;
      statusToLookUpThePurchaseOrder.value = false;
    };
    //Funcion para cargar una Nota de credito
    //Variables para la busqueda de nota de credito
    const showResultsCreditNote = ref(false);
    const loadingSearchCreditNote = ref(true);
    const resultSearchCreditNote = ref([]);
    const resultLengthCreditNote = ref(false);
    const searchCreditNotes = ref("");
    // esta funcion sirve para buscar usuario por nombre
    const searchCreditNote = async () => {
      if (searchCreditNotes.value.length <= 0) {
        errorActions(
          "Por favor ingresa <strong>el nombre de producto o su codigo de barra</strong>"
        );
        return;
      }

      try {
        loadingSearchCreditNote.value = true;
        showResultsCreditNote.value = true;
        resultLengthCreditNote.value = false;
        const response = await axios
          .get(
            `nota-de-credito-para-compras/?page=1&per_page=10&search=${searchCreditNotes.value}&idstore=${dataForm.value.idStore}`
          )
          .catch((error) => errorActions(error));
        resultSearchCreditNote.value = await response.data.data;
        if (resultSearchCreditNote.value.length > 0) {
          resultLengthCreditNote.value = true;
        }
        loadingSearchCreditNote.value = false;
      } catch (error) {
        errorActions(
          "Upsss. No pudimos encontrar el producto debido a que hemos tenido unos inconvenientes"
        );
      }
    };

    //Funcion para aplicar la nota de crédito
    const applyCreditNote = async (id, amount) => {
      totalCreditNote.value = parseFloat(amount);
      totalCreditNoteDiference.value =
        totalOfTheOrder.value - totalCreditNote.value;
      dataForm.value.idCreditNote = id;
      showResultsCreditNote.value = false;
    };

    return {
      loading,
      header,
      totalOfTheOrder,
      ivaOfTheOrder,
      subTotalOfTheOrder,
      money,
      format,
      dataForm,
      changeSupplierID,
      searchSupplier,
      resultLengthSupplier,
      showResultsSupplier,
      resultSearchSupplier,
      loadingSearchSupplier,
      addProducts,
      deleteDetailsTableToTable,
      handleQuantityChange,
      handlePriceChange,
      handleDescriptionLotChange,
      insert,
      spinner,
      totalCreditNoteDiference,
      totalCreditNote,
      handleExpiredChange,
      statusToLookUpThePurchaseOrder,
      activePurchaseOrderSearch,
      arrayOfDetails,
      getSelectTypeOfPayment,
      getSelectTypeOfSale,
      getSelectVouchersOfSale,
      stateInputTypePayment,
      discountApliedToOrder,
      stateForAccountsPayableSetup,
      searchPurchaseOrder,
      showResultsPurchaseOrder,
      loadingSearchPurchaseOrder,
      resultSearchPurchaseOrder,
      resultLengthPurchaseOrder,
      searchPurchaseOrders,
      addDataFromPurchaseOrder,
      showResultsCreditNote,
      loadingSearchCreditNote,
      resultSearchCreditNote,
      resultLengthCreditNote,
      searchCreditNotes,
      searchCreditNote,
      activeCreditNoteSearch,
      stateForCreditNote,
      applyCreditNote,
    };
  },
};
</script>
